import * as React from "react"


const NotFoundPage = () => (
  <span>
    <h1>Pàgina no trobada</h1>
    <p>No hi ha cap pàgina amb aquesta ruta.</p>
  </span> 
)

export default NotFoundPage
